import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Layout from '../components/Layout';
import { HTMLContent } from '../components/Content';
import Hero from '../components/Hero/Hero';
import styles from '../styles/basicpage.module.scss';

export const TermsOfServiceTemplate = ({
  seo,
  title,
  date,
  content,
  location,
}) => (
  <Layout seo={seo} location={location}>
    <div className={cx(styles.basicPage)}>
      <Hero classNames={['grey']}>
        <div className={cx('text')}>
          <h1>{ title }</h1>
          <h4>Last updated {date}</h4>
        </div>
      </Hero>
      <div className={cx('container')}>
        <HTMLContent content={content} />
      </div>
    </div>
    <script type="text/javascript">{`
        piAId = '824443';
        piCId = '1649';
        piHostname = 'pi.pardot.com';
        
        (function() {
          function async_load(){
            var s = document.createElement('script'); s.type = 'text/javascript';
            s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
            var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
          }
          if(window.attachEvent) { window.attachEvent('onload', async_load); }
          else { window.addEventListener('load', async_load, false); }
        })();
      `}
    </script>
  </Layout>
);

TermsOfServiceTemplate.propTypes = {
  title: PropTypes.string.isRequired,
};

const TermsOfService = ({ location, data }) => {
  const { markdownRemark: { frontmatter, html } } = data;
  return (
    <TermsOfServiceTemplate
      location={location}
      {...frontmatter}
      content={html}
    />
  );
};

TermsOfService.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default TermsOfService;

export const TermsOfServiceQuery = graphql`
  query TermsOfService {
    markdownRemark(frontmatter: { name: { eq: "termsOfService" } }) {
      frontmatter {
        seo {
          title
          description
        }
        title
        date(formatString: "MMMM DD, YYYY")
      }
      html
    }
  }
`;
